import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout , BlankLayout } from "./layouts";

// Route Views
import Errors from "./views/Errors";
import Setting from "./views/Setting";
import AuthPage from "./views/AuthPage";
import Network from "./views/Network";
import Campaign from "./views/Campaign";
import Home from "./views/Home";
import Lpreport from "./views/Lpreport";
import Report from "./views/Report";
import EditCampaign from "./views/EditCampaign";
import EditLpCampaign from "./views/EditLpCampaign";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/login",
    layout: BlankLayout,
    component: AuthPage
  },
  {
    path: "/setting",
    layout: DefaultLayout,
    component: Setting
  },
  {
    path: "/add-network",
    layout: DefaultLayout,
    component: Network
  },
  {
    path: "/add-campaign",
    layout: DefaultLayout,
    component: Campaign
  },
  {
    path: "/home",
    layout: DefaultLayout,
    component: Home
  },
  {
    path: "/lpreport/:file",
    layout: DefaultLayout,
    component: Lpreport
  },
  {
    path: "/report/:file",
    layout: DefaultLayout,
    component: Report
  },
  {
    path: "/edit-campaign/:file",
    layout: DefaultLayout,
    component: EditCampaign
  },
  {
    path: "/edit-lpcampaign/:file",
    layout: DefaultLayout,
    component: EditLpCampaign
  },
  {
    layout: DefaultLayout,
    component: Errors
  },
];

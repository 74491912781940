import React from "react";
import { Container } from "shards-react";

const BlankLayout = ({ children }) => (
  <Container fluid>
    {children}
  </Container>
);

export default BlankLayout;

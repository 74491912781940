export default function() {
  return [
    {
      title: "Home",
      htmlBefore: '<i class="material-icons">home</i>',
      to: "/home",
    },
    {
      title: "Add New Campaign",
      htmlBefore: '<i class="material-icons">library_add</i>',
      to: "/add-campaign",
    },
    {
      title: "Add/Edit Network",
      htmlBefore: '<i class="material-icons">language</i>',
      to: "/add-network",
    },
    {
      title: "Setting",
      htmlBefore: '<i class="material-icons">settings_applications</i>',
      to: "/setting",
    },
  ];
}

import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  NavLink,
  NavItem,
} from "shards-react";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  logOut() {
    localStorage.removeItem("ppvd_token");
    localStorage.removeItem("login_success");
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions} className="p-2 d-flex align-items-center justify-content-center">
          <NavLink tag={Link} to={"/"} onClick={this.logOut}>
            <i className="material-icons" style={{color: '#2F4050', fontSize:'1.6rem'}}>&#xE879;</i>
          </NavLink>
      </NavItem>
    );
  }
}

import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logoImage from "../images/logo/logo.png";
import { getApiUrl } from "./Global";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Version 5.0 © iPromote Media Inc. All Rights Reserved.'}
    </Typography>
  );
}

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class AuthPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      password: '',
      userNameError: false,
      passwordError: false,
    };
    this.login = this.login.bind(this);
    this.handleUserName = this.handleUserName.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  login() {
    fetch(getApiUrl() + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        user_name: this.state.userName,
        password: this.state.password,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          localStorage.setItem("login_success", "true");
          if ('token' in json) {
            localStorage.setItem("ppvd_token", json.token);
            this.props.history.push("home");
          }
          else {
            localStorage.removeItem("ppvd_token");
            if('validation' in json && window.confirm('Your email address is not validated! Do you want to change current email address?') === true)
              this.props.history.push("setting");
            else
              this.props.history.push("setting");
          }
        }
        else {
          this.setState({
            userNameError: true,
            passwordError: true,
            userName: '',
            password: '',
          })
          localStorage.removeItem("ppvd_token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleUserName(event) {
    if(event.target.value !== "")
      this.setState({
        userName: event.target.value,
        userNameError: false,
      })
    else
      this.setState({
        userName: event.target.value,
        userNameError: true,
      })
  }

  handlePassword(event) {
    if(event.target.value !== "")
      this.setState({
        password: event.target.value,
        passwordError: false,
      })
    else
    this.setState({
      password: event.target.value,
      passwordError: true,
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logoImage} alt="PPV Dominator" />
          <div className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="User Name"
              name="username"
              onChange={this.handleUserName}
              autoFocus
              error={this.state.userNameError}
              value={this.state.userName}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={this.handlePassword}
              error={this.state.passwordError}
              value={this.state.password}
              onKeyDown={this.handleKeyDown}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.login}
            >
              Sign In
            </Button>
          </div>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default withStyles(styles)(AuthPage);


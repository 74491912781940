import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormGroup,
  Card,
  CardHeader,
  Container,
  FormSelect,
} from "shards-react";
import Button from '@material-ui/core/Button';
import {getApiUrl} from "./Global";

class Campaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duplicate: '',
      network: '',
      type: '',
      cpv: '',
      group: '',
      campaign: '',
      networkList: [],
      typeList: [
        {
          value: "redirect",
          str: "Direct Link",
        },
        {
          value: "meta",
          str: "Meta Refresh",
        },
        {
          value: "frame",
          str: "Frame",
        },
        {
          value: "lp",
          str: "Landing Page",
        },
      ],
      duplicateList: [
        "first",
        "second",
      ],
      campaigns: [],
    };

    this.create = this.create.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleNetwork = this.handleNetwork.bind(this);
    this.handleType = this.handleType.bind(this);
    this.handleCpv = this.handleCpv.bind(this);
    this.handleGroup = this.handleGroup.bind(this);
    this.handleCampaign = this.handleCampaign.bind(this);
    this.getCampaigns = this.getCampaigns.bind(this);
  }

  getCampaigns() {
    fetch(getApiUrl() + 'get_campaigns', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      }
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          this.setState({
            networkList: json.platforms,
            network: json.platforms[0].name,
            type: this.state.typeList[0].value,
            campaigns: json.campaigns,
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    if(!localStorage.getItem("ppvd_token")) {
      alert("You can access this page now.");
      this.props.history.push("login");
    }
    else
      this.getCampaigns();
  }

  handleDuplicate(event) {
    this.setState({
      duplicate: event.target.value,
    });
  }

  handleNetwork(event) {
    this.setState({
      network: event.target.value,
    });
  }

  handleType(event) {
    this.setState({
      type: event.target.value,
    });
  }

  handleCpv(event) {
    this.setState({
      cpv: event.target.value,
    });
  }

  handleGroup(event) {
    this.setState({
      group: event.target.value,
    });
  }

  handleCampaign(event) {
    this.setState({
      campaign: event.target.value,
    });
  }

  create() {
    console.log({
      file: this.state.campaign,
      platform: this.state.network,
      global_type: this.state.type,
      cpc: this.state.cpv,
      group: this.state.group,
      newfrom: this.state.duplicate,
    });
    fetch(getApiUrl() + 'create_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'token': localStorage.getItem("ppvd_token"),
      },
      body: JSON.stringify({
        file: this.state.campaign,
        platform: this.state.network,
        global_type: this.state.type,
        cpc: this.state.cpv,
        group: this.state.group,
        newfrom: this.state.duplicate,
      })
    })
      .then(response => response.json())
      .then(json => {
        if ('success' in json && json.success) {
          this.setState({
            campaign: '',
            network: this.state.networkList[0].name,
            type: this.state.typeList[0].value,
            cpv: '',
            group: '',
            duplicate: 'blank',
          })
          alert("Success");
        }
        else
          alert("Failed");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const items = [];
    var campaigns = this.state.campaigns;
    for(var i = 0; i < campaigns.length; i++) {
      if(campaigns[i].type !== this.state.type) continue;
      items.push(
        <option value={campaigns[i].name} key={i}>{campaigns[i].name}</option>
      )
    }
    return (
      <Container fluid className="main-content-container p-4">
        <Row className="justify-content-center">
          <Col>
            <Card small>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Creat New Campaign</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Create Campaign</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleCampaign} value={this.state.campaign}/>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Network</label>
                          <Col md={9}>
                            <FormSelect onChange={this.handleNetwork} value={this.state.network}>
                              {this.state.networkList.map((item, index) =>
                                <option value={item.name} key={index}>{item.name}</option>
                              )}
                            </FormSelect>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Type</label>
                          <Col md={9}>
                            <FormSelect onChange={this.handleType} value={this.state.type}>
                              {this.state.typeList.map((item, index) =>
                                <option value={item.value} key={index}>{item.str}</option>
                              )}
                            </FormSelect>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">CPV</label>
                          <Col md={9}>
                            <FormInput  onChange={this.handleCpv} value={this.state.cpv}/>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Group</label>
                          <Col md={9}>
                            <FormInput onChange={this.handleGroup} value={this.state.group}/>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <label className="col-md-2 col-form-label label-responsive">Duplicate</label>
                          <Col md={9}>
                            <FormSelect onChange={this.handleDuplicate} value={this.state.duplicate}>
                              <option value="blank"></option>
                              {items}
                            </FormSelect>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col md={11} className="text-right">
                            <Button variant="contained" color="primary" className="save-button" onClick={this.create}>Create Campaign</Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Campaign;
